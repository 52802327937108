@import './src/scss/variables';
@import './src/scss/app';

.widget__result {
	&-text {
		line-height: 1.4;
		text-align: left;
		max-height: $max-height;
    overflow-y: auto;
    border: 1px solid;
    padding: .5rem .5rem 0;
    width: 100%;
	}

  p {
    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }

  ul {
    list-style: disc;
    margin-left: 1.5rem;
  }

  li {
    &:not(:last-child) {
      padding-bottom: .5rem;
    }
  }

  #copy-container {
    padding-bottom: .5rem;
  }
}